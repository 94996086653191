.wrapper {
    margin-bottom: 12px;
    padding: 5px 7px;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.heading {
    font-size: 11px;
    font-weight: 700;
}