.menu-list-custom {
    overflow-y: scroll;
    max-height: 690px;
    margin-top: 10px;
    border-radius: 12px;
}

.menu-list-custom li {
    margin-bottom: 0px;
}

.menu-list-custom li a {
    display: block;
    padding-left: 6px;
    margin-bottom: 10px;
    color: #363636;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s;
}

.menu-list-custom li a:hover {
    font-weight: bold;
    border-radius: 24px;
    color: black;
}

.dropdown-menu-custom li a:hover {
    font-weight: bold;
    border-radius: 24px;
    color: black;
}

.menu-list-custom li a strong {
    display: block;
    font-size: 1.1em;
}

.menu-list-custom li a small {
    color: black;
}

.chat-container-custom {
    display: flex;
    flex-direction: column;
    height: 76vh;
    box-sizing: border-box;
    position: relative;
}

.messages-custom {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: 78vh;
}

@media (max-width: 768px) {

    /* Adjust breakpoint as needed */
    .messages-custom {
        height: 60vh;
    }
}

.messages-container {
    /* flex-grow: 1; */
    display: flex;
    flex-direction: column-reverse;
    /* changes the direction to reverse - bottom up */
    /* flex-direction: column; */
    padding: 4px;
    border: 1px solid rgb(255, 255, 255);
    border-radius: 12px;
    overflow-y: scroll;
    scrollbar-width: none;
    /* Hide scrollbar in Firefox */
    height: 100%;
    text-align: start;
}

.user-message-icon {
    width: 45px;
    height: 40px;
    border-radius: 50%;
    background-color: #032B98;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    margin-bottom: auto;
}

.slb-message-icon {
    width: fit-content;
    padding: 8px;
    border-radius: 50%;
    background-color: #51C9EB;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    margin-bottom: auto;
    margin-top: 5px;
}


.message-custom {
    margin-top: 10px;
    padding: 7px;
    padding-left: 10px;
    padding-right: 10px;
    border: 1px solid rgb(255, 255, 255);
    border-radius: 24px;
    margin-bottom: 10px;
    width: 100%;
}

.card-custom-businesses {
    border-radius: 8px;
    overflow: hidden;
    height: fit-content;
}

/* Business cards for mobile view */
.horizontal-business-card {
    display: inline-flex;
    flex-direction: column;
    flex: 0 0 33.33%;
    margin-right: 10px;
    margin-bottom: 10px;
    width: 180px;
    overflow: auto;
}

.business-image-business-card {
    border-radius: 10% 10% 0% 4% / 10% 10% 0% 4%;
    height: 122px;
    object-fit: cover;
    width: 100%;
}

/* Custom DropDown */
.custom-dropdown {
    position: relative;
    display: inline-block;
}

.setting-icon-custom {
    border: 1px solid rgb(83, 83, 83);
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 5px;
    border-radius: 50px;
}

.dropdown-menu-custom {
    position: absolute;
    top: 100%;
    left: -200%;
    transform: translateX(-50%);
    margin-top: 0.5em;
    background-color: #fff;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 0.5em 0;
    width: 200px;
    z-index: 1000;
    list-style: none;
    height: 450px;
    overflow: scroll;
}

.notification-badge {
    position: absolute;
    right: 0;
    border-radius: 10px;
    color: white;
    font-weight: bold;
    width: 24px;
    height: 22px;
    font-size: 10px;
    border: 2px solid white;
    background-color: #ee2a2a;
    margin-top: -70%;
    display: flex;
    align-items: center;
    justify-content: center;
}