.wrapper {
    border-top: 7px solid #3298dc;
    padding: 18px;
}

.heading {
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 18px;
}